.pricing-info {
  max-width: 800px;
  margin: auto;
}

.table {
  margin-bottom: 1.5rem;
}

.additional-info,
.info-section {
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f9f9f9;
}

h4 {
  margin-bottom: 1rem;
}

ul {
  list-style-type: disc;
  padding-left: 1.5rem;
}

li {
  margin-bottom: 0.5rem;
}

.payment-info {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.payment-option {
  text-align: center;
  margin-bottom: 20px;
}

.payment-icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 10px;
}

.payment-option h4 {
  margin-top: 10px;
  font-size: 1.25rem;
  color: #333;
}

.payment-option p {
  font-size: 1rem;
  color: #666;
}
