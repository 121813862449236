.about-us-container {
  background-color: #f8f9fa;
  height: 80vh;
}

.contact-info p {
  font-size: 1.2rem;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 700;
}

.lead {
  font-size: 1.25rem;
}

.rounded {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.social-media-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-icon {
  font-size: 2rem;
  color: #333;
}

.social-icon:hover {
  color: #007bff;
}

.lottieWrapper {
  position: relative;
  bottom: 20%;
}
