.calculator {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  /* max-width: 600px; */
  margin: 20px auto;
}

h2 {
  color: #333;
}

.service-section {
  margin-bottom: 20px;
}

label {
  display: block;
  margin: 10px 0;
}

.quantity-input {
  width: 60px;
  margin-left: 10px;
}

.icon {
  color: #007bff;
  margin-right: 10px;
}

.total-cost {
  background-color: #36739e;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .quantity-input {
    width: 50px;
  }

  .calculator {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .quantity-input {
    width: 40px;
  }

  h2 {
    font-size: 1.5rem;
  }
}
