.bullet-container {
  padding: 20px;
  background-color: transparent;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 600px;
  text-align: left;
}

.custom-bullet-list {
  list-style-type: disc; /* Bullet point style */
  margin: 0;
  padding-left: 20px;
  color: #333;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1em;
}

.custom-bullet-list li {
  margin-bottom: 10px; /* Spacing between list items */
  line-height: 1.5;
}

.custom-bullet-list li::marker {
  color: #007bff; /* Custom color for the bullet point */
}
