body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  -moz-osx-font-smoothing: grayscale;
}
header {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}
