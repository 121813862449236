.parallax {
  position: relative; /* Needed for the absolute positioning of the overlay */
  background-image: url("../../assets/images/backgroung-img.jpg");
  height: 85vh;
  background-attachment: fixed;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1; /* To ensure it's behind the overlay content */
}

.parallax::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
  backdrop-filter: blur(8px); /* Blur effect for the overlay */
  z-index: 2; /* Higher than the background, but lower than the content */
}

.parallax-content {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  position: relative;
  z-index: 3; /* Higher than the overlay */
  color: #a7e6ff;
  padding-top: 50px;
}

.home-title {
  font-size: 4rem;
  font-weight: bold;
  color: rgb(255, 255, 255);
}

.home-subtitle {
  font-size: 1.5rem;
  margin-top: 1rem;
}

/* CSS */
.home-button {
  margin-top: 2rem;
  height: 50px;
  padding: 0.75rem 2rem;
  font-size: 1.25rem;
  background-color: #fbeee0;
  color: #f1f5f7 !important;
  border: 2px solid #422800;
  border-radius: 30px !important;
  box-shadow: none 4px 4px 0 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  padding: 0 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.home-button:hover {
  background-color: #01586d;
}

.home-button:active {
  box-shadow: #115562 2px 2px 0 0;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .home-button {
    min-width: 120px;
    padding: 0 25px;
  }
}

.service-container {
  height: 80vh;
}

.primary-color {
  background-color: #3abef9;
}
.primary-color1 {
  background-color: #3572ef;
}
.primary-color1 {
  background-color: #a7e6ff;
}

.backdrop {
  background-color: #a7e6ff;
}

.navbar-wrapper {
  background-color: #3abef9;
}

.header-text {
  color: #fbfbfb !important;
  font-weight: 500;
}

.font-dls {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
}

.custom-dls-card {
  border: none;
  border-width: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  text-align: center;
}

.container-layout {
  height: 80vh !important;
  background: rgb(58, 190, 249);
  background: linear-gradient(
    90deg,
    rgba(58, 190, 249, 1) 0%,
    rgba(167, 237, 255, 1) 0%,
    rgba(58, 190, 249, 1) 70%,
    rgba(167, 237, 255, 1) 90%
  );
}

.testimonial {
  background: rgb(198, 225, 237);
  background: linear-gradient(
    90deg,
    rgba(0, 177, 255, 1) 0%,
    rgba(225, 239, 243, 1) 0%,
    rgba(247, 247, 247, 1) 90%
  );
}

.titleText {
  color: #0a41ae;
  font-weight: bold;
}

/* Float Shadow */
.hvr-float-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-float-shadow:before {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 0;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.35) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  /* W3C */
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
  transition-property: transform, opacity;
}
.hvr-float-shadow:hover,
.hvr-float-shadow:focus,
.hvr-float-shadow:active {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  /* move the element up by 5px */
}
.hvr-float-shadow:hover:before,
.hvr-float-shadow:focus:before,
.hvr-float-shadow:active:before {
  opacity: 1;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);
  /* move the element down by 5px (it will stay in place because it's attached to the element that also moves up 5px) */
}

.floating-wrapper {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80vh !important; /* Ensure wrapper covers full viewport */
  width: 100vw !important; /* Ensure wrapper covers full viewport */
}

.floating-modal {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(2px);
  position: absolute !important;
  background-color: transparent !important;
}

/* CSS */
.button-87 {
  margin: 10px;
  padding: 15px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  border-radius: 10px;
  display: block;
  border: 0px;
  color: #2c4a85;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(
    45deg,
    #2c4a85 0%,
    rgba(0, 177, 255, 1) 0%,
    rgba(247, 247, 247, 1) 90%
  );
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}

.dark-title {
  font-size: 4rem;
  font-weight: bold;
  color: #2c4a85;
}

.d-none-s {
  display: block;
}

@media (max-width: 768px) {
  .d-none-s {
    display: none;
  }
  .floating-modal {
    text-align: center;
  }

  .dark-title {
    font-size: 1.5rem;
  }

  .home-subtitle {
    font-size: 1rem;
  }

  .lottie-container canvas {
    max-width: 300px; /* Scale down the Lottie animation */
    height: auto;
  }
}
