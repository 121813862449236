/* StickyCallButton.css */

.sticky-call-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #13569e;
  color: white;
  padding: 15px 20px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.sticky-call-button:hover {
  transform: scale(1.1);
  background-color: #0056b3; /* Darker blue on hover */
}
