.carousel-container {
  width: 100%;
  /* height: 80vh;  */
  /* Set height of the carousel */
  overflow: hidden; /* Hide overflow to prevent large images from spilling out */
}

.carousel-item img {
  width: 100%;
  /* height: 70vh;  */
  /* Adjust based on how tall you want the carousel */
  object-fit: contain;
}

.carousel-item {
  max-height: 70vh; /* Adjust the height of the carousel */
}

.carousel-caption {
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.carousel {
  position: relative;
  height: 80%;
}

.carousel-background {
  width: 100%;
  height: 60vh; /* Adjust based on your preferred height */
  background-size: contain; /* Or use 'contain' if you want to show the entire image */
  background-position: center;
  background-repeat: no-repeat;
}

.slide1 {
  background-image: url("../../assets/images/sliderImg1.jpg"); /* Path to your first image */
}

.slide2 {
  background-image: url("../../assets/images/sliderImage2.jpg"); /* Path to your second image */
}

.slide3 {
  background-image: url("../../assets/images/sliderImage3.jpg"); /* Path to your third image */
}

.slide4 {
  background-image: url("../../assets/images/backgroung-img.jpg"); /* Path to your third image */
}
.slide5 {
  background-image: url("../../assets/images/laundry.jpg"); /* Path to your third image */
}
